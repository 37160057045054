/***
*
*   LOGO
*   Replace the image in /images with your own logo
*
**********/

import React from 'react';
import ClassNames from 'classnames';
import { Link } from 'components/lib';
// import LogoImage from './images/logo.svg';
import LogoImage from './images/floodlight-logos_Dark Horizontal_small.png';
import LogoMark from './images/floodlight-logos_White Icon.png';
import Style from './logo.module.scss';

export function Logo(props){

  const css = ClassNames([
      Style.logo,
      props.className,
      props.label && Style.wrapper
  ]);

  let url_path;
  if(props.path === null || props.path === undefined)
    url_path = "/dashboard";
  else
    url_path = props.path;

  return(
    <Link url={url_path} className={ css }>
      <img src={ props.mark ? LogoMark : LogoImage } alt='Logo' height={props.height} className={Style.img}/>
      {props.label && <span className={Style.label}>{props.label}</span> }
    </Link>
  )
}
