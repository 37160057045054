import React, {useEffect, useState} from 'react';
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import { Loader, useAPI} from "../../lib";
import {StripeWrapperPaymentForm} from "../stripe_wrapper/stripe_wrapper";

const Settings = require('settings.json');
console.log(process.env.NODE_ENV);
console.log(Settings[process.env.NODE_ENV].stripe.publishableAPIKey);
const StripePromise = loadStripe(Settings[process.env.NODE_ENV].stripe.publishableAPIKey);

export function CheckoutForm() {
    const [clientSecret, setClientSecret] = useState("");
    const [clientSecretLoaded, setClientSecretLoaded] = useState(false);
    const clientSecretData = useAPI("/api/purchase_intent", 'post', {items: [{id: "xl-tshirt"}]})

    useEffect(() => {
        console.log(clientSecretData);
        if (clientSecretData?.data?.clientSecret) {
            setClientSecret(clientSecretData.data.clientSecret);
            setClientSecretLoaded(true);
        } else
            return;
    }, [clientSecretData]);

    if (!clientSecretLoaded) return (
        <Loader/>
    );

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };

    return (
        <Elements stripe={StripePromise} options={options}>
            <StripeWrapperPaymentForm clientSecret={clientSecret}/>
        </Elements>
    );
}