/***
 *
 *   Address Page
 *
 **********/

import React, {useEffect, useState} from 'react';
import {Animate, Card, Feedback, Form, History, Loader, Message, Row, useAPI} from 'components/lib';
import {DisabledDiv} from "../../components/disabled/disabled";

export function AddressForm(props) {
    // const user = useAPI('/api/user');
    const [disabled, setDisabled] = useState(false);
    const creditsData = useAPI('/api/account/getcredits');
    useEffect(() => {
        if (creditsData?.data?.report_credits === 0) {
            setDisabled(true);
        }
    }, [creditsData]);
    if (creditsData.loading)
        return <Loader/>
    const credits = creditsData?.data?.report_credits;
    return (
        <Animate type='pop'>
            {credits === 0 &&
                <Message
                    type='error'
                    title='No Report Credits Remaining'
                    text='You Currently have no report credits remaining. Please purchase more credits to continue using SAGE'
                    buttonLink='/credits_purchase'
                    buttonText='Purchase Credits'
                    buttonPullRight={true}
                />
            }
            <Card>
                <Row
                    title='Submit a New Request'
                    desc='Using the form below, please submit a new request for SAGE.'
                >
                    <DisabledDiv
                    disabled={disabled}>
                        <Form
                            data={{
                                project_name: {
                                    label: 'Name for the Project',
                                    type: 'text',
                                    required: true,
                                    errorMessage: 'Please Enter a Project Name'
                                },
                                email: {
                                    label: 'Email for Status Tracking (Optional)',
                                    type: 'email',
                                    required: false,
                                    errorMessage: 'Please enter your email address'
                                },
                                address: {
                                    label: 'Address',
                                    type: 'projectAddress',
                                    required: true,
                                    errorMessage: 'Please enter address'
                                },
                                start_date: {
                                    label: 'Select Target Start Year',
                                    type: 'select',
                                    required: true,
                                    errorMessage: 'Please enter a valid date',
                                    default: '2022',
                                    options: [
                                        {label: '2023', value: '2023'},
                                        {label: '2022', value: '2022'},
                                        {label: '2021', value: '2021'},
                                        {label: '2020', value: '2020'},
                                        {label: '2019', value: '2019'},
                                        {label: '2018', value: '2018'},
                                        {label: '2017', value: '2017'},
                                        {label: '2016', value: '2016'}
                                    ],
                                },
                                end_date: {
                                    label: 'Select Target End Year',
                                    type: 'select',
                                    required: true,
                                    errorMessage: 'Please enter a valid date',
                                    default: '2022',
                                    options: [
                                        {label: '2022', value: '2022'},
                                        {label: '2021', value: '2021'},
                                        {label: '2020', value: '2020'},
                                        {label: '2019', value: '2019'},
                                        {label: '2018', value: '2018'},
                                        {label: '2017', value: '2017'},
                                        {label: '2016', value: '2016'}
                                    ],
                                },
                                scope_1: {
                                    label: 'Include Scope 1',
                                    type: 'switch',
                                    default: true,
                                    errorMessage: 'Please Chose'
                                },
                                scope_2: {
                                    label: 'Include Scope 2',
                                    type: 'switch',
                                    default: false,
                                    errorMessage: 'Please Chose'
                                },
                            }}
                            buttonText='Submit Request'
                            small_button={true}
                            pullButtonRight={true}
                            method='POST'
                            url='/api/utility/submit_sage_request'
                            callback={e => History.push('/sage/')}
                        />
                    </DisabledDiv>
                </Row>
            </Card>
            <Feedback/>
        </Animate>
    );
}
