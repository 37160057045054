/***
*
*   SIGN IN
*   Sign in form for all account types (including master).
*
**********/

import React, { useContext } from 'react';
import { Animate, AuthContext, Form, Card, Link, Row } from 'components/lib';

export function Signin(props){

  const context = useContext(AuthContext);

  return(
    <Animate type='pop'>
      <Row title='Sign in to Floodlight'>
        <Card restrictWidth center>
          <Form
            data={{
              email: {
                label: 'Email',
                type: 'email',
                required: true,
              },
              password: {
                label: 'Password',
                type: 'password',
                required: true
              },
              forgotpassword: {
                type: 'link',
                url: '/forgotpassword',
                text: 'Forgot your password?'
              }
            }}
            url='/api/user/auth'
            method='POST'
            buttonText='Sign In'
            callback={ context.signin }
          />

          <span>Don't have an account? <Link url='/sign_up' text='Sign Up'/></span>

        </Card>
      </Row>
    </Animate>
  );
}
