/***
 *
 *   DASHBOARD
 *   Template dashboard.
 *
 **********/
import React, { useEffect } from 'react';
import { useAPI } from "../hooks/api";
import { Loader } from "../loader/loader";
import Style from './tabluea_wrapper.module.scss';


export function FullTableauDashboard(props){
    useEffect(() => {
        if (window && document) {
            const script = document.createElement('script');
            const body = document.getElementsByTagName('body')[0];
            // script.src = 'https://embedding.tableauusercontent.com/tableau.embedding.3.1.0.min.js';
            script.src = process.env.PUBLIC_URL + '/tableau.embedding.3.1.0.min.js';
            script.type = 'module';
            body.appendChild(script)
            // script.addEventListener('load', () => {
            //     console.log("here");
            // })
        }
    }, []);
    const tkn = useAPI('/api/products/ttkn');
    const re_link = '/api/product/tb_link/' + props.project;
    const re = useAPI(re_link);
    console.log(re);

    if(tkn.loading || (re.loading && re.data !== null))
        return <Loader />

    return (
        <div className={Style.full_report_wrapper_div}>
            <tableau-viz id="tableauViz"
                     toolbar="hidden"
                     token={tkn.data}
                     src={re.data?.tableau_url}>
            </tableau-viz>
        </div>
    );
}