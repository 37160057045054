/***
*
*   PROFILE
*   Update the user profile or close the account
*
**********/

import React, { Fragment, useContext } from 'react';
import { AuthContext, ViewContext, Form, Card,
  AccountNav, Button, History, Animate, useAPI } from 'components/lib';

export function Profile(props){

  // context
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);

  // fetch
  const user = useAPI('/api/user');

  function closeAccount(){
    viewContext.modal.show({

      title: 'Close Your Account',
      form: closeAccount,
      buttonText: 'Close Account',
      url: '/api/account',
      method: 'DELETE',
      text: 'Are you sure you want to delete your account? ' +
      'You will lose all of your data and this can not be undone.',

    }, () => {

      // destory user
      localStorage.clear();
      History.push('/sign_up');

    });
  }

  return (
    <Fragment>
      <AccountNav />
      <Animate>
        <Card
          title='Edit Your Profile Information'
          loading={ user.loading } restrictWidth>

          { user?.data &&
            <Form
              buttonText='Save'
              url={ authContext.user.permission === 'owner' ? '/api/account' : '/api/user' }
              method='PATCH'
              data={{
                name: {
                  label: 'Name',
                  type: 'text',
                  required: true,
                  value: user.data.name,
                  errorMessage: 'Please enter your name',
                },
                email: {
                  label: 'Email address',
                  type: 'email',
                  required: true,
                  value: user.data.email,
                  errorMessage: 'Please enter your email address'
                }
              }}
              callback={ res => authContext.update({ name: res.data.data.name })}
            />
          }

          { authContext.permission.owner &&
            <Fragment>
              <br/>
              <Button
                textOnly
                action={ closeAccount }
                text='Close Your Account'
              />
            </Fragment>
          }
        </Card>
      </Animate>
    </Fragment>
  );
}
