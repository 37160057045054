/***
*
*   HOMEPAGE
*   Homepage template with features, testimonials and CTA
*
**********/

import React, { Fragment } from 'react';
import ImgDashboard from './images/example.png';
import backgroundImage from './images/flbg.png';
import backgroundImage2 from './images/flbg2.png';
import Style from './home.module.scss';
import {History} from "components/lib";

export function Home(){

  return(
    <Fragment>
        <div className={Style.bgImg}>
            <img src={backgroundImage2}  alt="background image swoosh"/>
        </div>
        <div className={Style.bgImg}>
            <img src={backgroundImage}  alt="background image swoosh"/>
        </div>
        <div className={Style.contentWrapper}>
            <div className={Style.frameParent}>
                <div className={Style.aiDrivenEsgAnalyticsAtYouParent}>
                    <b className={Style.aiDrivenEsgAnalytics}>AI-Driven Analytics at your Fingertips.</b>
                    <div className={Style.exploreData1}>{`Explore data & analytics through our Platform for emissions, climate, and risk analysis.`}</div>
                </div>
                <div className={Style.buttonbase} onClick={() => History.push('/sign_up')}>
                    <div className={Style.text1}>Sign up now</div>
                </div>
            </div>
            <div>
                <div>
                    <img className={Style.imgWrapper} src={ImgDashboard}  alt="dashboard"/>
                </div>
            </div>
        </div>
    </Fragment>
  );
}
