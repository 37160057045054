import React, {Fragment, useEffect, useState} from 'react';
import {FileDropzone} from "../../../components/file_dropzone/file_dropzone";
import {Button, Card, Row, Table, TextInput} from "../../../components/lib";
import {MapScannerWrapper} from "./map_scanner_wrapper";

export function AddressVerificationOracle(props) {
    const [results, setResults] = useState([]);
    const [totalResults, setTotalResults] = useState(0);
    const [processing, setProcessing] = useState(false);
    const [currentResult, setCurrentResult] = useState(0);
    const [textSearch, setTextSearch] = useState("Enter Business Name Here");

    useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyCYzFo6W4UbCUEnSkYBR_VXNfjsldx7zEw&libraries=places,geometry";
        script.async = false;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    useEffect(() => {
        setTotalResults(results.length);
    }, [results]);

    const dropzoneOnDrop = (files) => {
        for(let f of files){
            const fileReader = new FileReader();
            fileReader.onload = async (_) => {
                const text = fileReader.result;
                const lines = text.split('\n');
                const obj_ = [];
                let idx = 0;
                for(let line of lines){
                    obj_.push(
                        {
                            "ID": idx,
                            "Address": line,
                            "Map": <MapScannerWrapper filter={textSearch} idx={idx} address={line} callback={mapCallCompleteCallback} shouldProcess={false}/>,
                            "Status": "Queued",
                            "Result": "",
                            "Verification": ""
                        });
                    idx++;
                }
                setResults(obj_);
            }
            fileReader.readAsText(f.file);
        }
    }

    const mapCallCompleteCallback = (idx, result, filterFound) => {
        results[idx].Status = "Complete";
        results[idx].Result = result.name;
        results[idx].Verification = filterFound ? "Found" : "Not Found, Double Check.";
        const newResults = results.map( (c, i) => {
            if(i === idx){
                const newC = c;
                newC.Status = "Complete";
                newC.Result = result.name;
                return newC;

            }
            return c;
        });
        setResults(newResults);
        setCurrentResult(currentResult + 1);
    }

    const textInputChangeCallback = (name, value, valid) => {
        setTextSearch(value);
    }

    const processButtonClick = () => {
        if(processing) {
            setProcessing(false);
        } else {
            setProcessing(true);
            for(let row of results){
                row.Map = <MapScannerWrapper filter={textSearch} idx={row.ID} address={row.Address} callback={mapCallCompleteCallback} shouldProcess={true}/>;
            }
        }
    }

    const exportResultsToCSV = () => {
        const headers = ["ID", "Address", "Result"];
        const csvData = results.map(row => {
            return [row.ID, row.Address, row.Result];
        });
        let csvContent = "";
        csvContent += headers.join(",") + "\n";
        csvData.forEach(function(rowArray){
            let row = rowArray.join(",");
            csvContent += row + "\n";
        });
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", "results.csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <Fragment>
            <p>This tool allows you to upload a csv file and then use the Google Maps API to find the business nearest that address.
                Carefully note that Google Maps has been explicitly dicouraged and in some cases prohibited from being used in China.
                If you are using this tool in China, other options will need to be explored in this order: Baidu Maps, Bing Maps, Open Street Maps, and Apple Maps,
                All of which are not explicitly prohibited in China. This does not mean that Google Maps is wrong, but can have
                inconsistent and incomplete results in China.
            </p>
            <FileDropzone
                fileChangeCB={dropzoneOnDrop}
                >
                Currently only csv files are supported.
            </FileDropzone>
            <Card
                title="Results & Processing"
                >
                <TextInput
                    name="textSearch"
                    label="Validation Check"
                    value={textSearch}
                    onChange={textInputChangeCallback}
                />
                <p>You must click process to proceed. </p>
                <Row
                    align="right"
                >
                    Total Records: {totalResults}
                    <br/>
                    <Button
                        text={processing? "Pause" : "Process"}
                        action={processButtonClick}
                        />
                </Row>
                <Table
                    data={results}
                    search
                />
                <Row>
                    <Button
                        pullRight
                        text="Download Results"
                        action={exportResultsToCSV}
                        />
                </Row>
            </Card>
        </Fragment>
    );
}