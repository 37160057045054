/***
 *
 *   Address Page
 *
 **********/

import React, {useState} from 'react';
import {Animate, Feedback, Loader, Card, Row, ProjectSteps, Button} from 'components/lib';

import {useParams} from "react-router-dom";
import Axios from "axios";
import usePollingEffect from "../../components/hooks/polling";
import {BackButton} from "../../components/nav/back_banner/back_banner";
import {FullTableauDashboard} from "../../components/tableau_wrapper/full_report";
import {BsArrowBarRight, BsCheck2Circle, BsSearch} from "react-icons/bs";
import {HiOutlineInboxArrowDown} from "react-icons/hi2";
import {MdOutlineMapsHomeWork} from "react-icons/md";
import {GiRadiations} from "react-icons/gi";

const options = {
    'Submitted': {name: 'Submitted', url: "", percent: 5, icon: BsArrowBarRight},
    'Received': {name: "Received", url: "", percent: 25, icon: HiOutlineInboxArrowDown},
    'Working on Risk Metrics': {name: "Working on Risk Metrics", url: "", percent: 40, icon: MdOutlineMapsHomeWork},
    'Working on Emissions': {name: "Working on Emissions", url: "", percent: 60, icon: GiRadiations},
    'Quality Checks': {name: "Quality Checks", url: "", percent: 80, icon: BsSearch},
    'Complete': {name: "Complete", url: "", percent: 100, icon: BsCheck2Circle}
}

export function RestProjectLanding(props) {
    const {project} = useParams();
    const [project_data, setData] = useState({});
    // const project_data = useAPI('/api/projects/rest/' + project);
    const [status, setStatus] = useState();
    const [statusIndex, setStatusIndex] = useState(0);
    const [percentCompleted, setPercentCompleted] = useState();
    const [projectName, setProjectName] = useState();
    const [showTableauReport, setShowTableauReport] = useState(false);

    async function t() {
        if(status === "Complete")
            return;  // Don't keep polling if the project is complete
        const res = await Axios({

            url: '/api/projects/rest/' + project,
            method: 'get',

        });
        let p = res.data.data;
        setData(p);
        setStatus(p.project_status);
        if (p.project_status !== undefined) {
            setStatusIndex(Object.keys(options).indexOf(p.project_status));
            if (p.project_status !== 'Complete') {
                setStatusIndex(statusIndex + 1);
            }
            setPercentCompleted(options[p.project_status].percent);
            setProjectName(p.project_name);
        } else {
        }
        console.log("Polling");
    }

    usePollingEffect(
        async () => setData(t()),
        [],
        {
            interval: 10000, onCleanUp: () => {
            }
        } // optional
    );

    // useEffect( () => {
    //     console.log(project_data);
    //
    // }, [project_data]);

    if (project_data.loading)
        return <Loader/>

    if (showTableauReport)
        return (
            <>
                <BackButton
                    callback={e => setShowTableauReport(false)}
                />
                <FullTableauDashboard
                    project={project}
                />
            </>
        );

    return (
        <Animate type='pop'>
            <Card
                title={'REST - ' + projectName + ' - Project Tracker & Deliverables'}
                center
            >
                <Row
                    title={'Project: ' + projectName + ' (' + status + ')'}
                    desc='Below Shows the status of the current Project'
                >
                    <ProjectSteps
                        percentCompleted={percentCompleted}
                        items={Object.values(options)}
                        activeIndex={statusIndex}
                    />
                </Row>
                {
                    status === 'Complete' &&
                    <Row
                    >
                        <Button
                            text='Download Excel Data'
                            color={'gray'}
                            disabled
                            small
                        />
                        <Button
                            text='View Report'
                            color={'blue'}
                            small
                            action={ e => setShowTableauReport(true) }
                        />
                    </Row>
                }
            </Card>
            <Feedback/>
        </Animate>
    );
}
