/***
*
*   HEADER
*   Header section with title used in main application (can render children)
*
*   PROPS
*   title: title of the view
*   children: children to render (optional)
*
**********/

import React from 'react'
import Style from './header.module.scss';

export function Header(props){

  return (
    <div className={ Style.header }>

      { props.title && <div className={Style.title}>{ props.title }</div> }
      { props.children }

    </div>

  );
}
