/***
*
*   AUTHENTICATION
*   Auth provider to manage auth functions throughout
*   the application. <PrivateRoute> component to
*   protect internal application routes from unauthenticated
*   access.
 *
 *   TODO: This currently uses localstorage, but to be honest it should not. We need to revisit this to make sure
 *    that it's secure as well as best practice. This is not really a priority right now, but it should be addressed.
*
**********/

import React, { useState, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAPI } from 'components/lib';
import {AuthContext} from "./user_context";
const permissions = require('./permissions');

export function AuthProvider(props){

  const invalidateCache = () => localStorage.clear();

  // This is a backstop on browsers that don't have the TTL set on the localstorage.

  let cache = JSON.parse(localStorage.getItem('user'));
  if(cache !== null){
    if(cache.hasOwnProperty('expires') && cache.expires < Date.now())
      invalidateCache();
    if(!cache.hasOwnProperty('expires'))
      invalidateCache();
  }
  
  const [user, setUser] = useState(cache);
  const subscription = useAPI(user ? '/api/account/subscription' : null);

  useEffect(() => {

    // update subscription status
    if (!subscription.loading)
      update({ subscription: subscription.data });

  }, [subscription]);

  function signin(res){

    if (res.data){
      const userData = res.data;
      userData.expires = Date.now() + 1000 * 60 * 60 * 24 ; // 24 hours
      localStorage.setItem('user', JSON.stringify(userData));

      window.location = 
        res.data.permission === 'master' ? '/master/dashboard' : 
        (res.data.onboarded ? '/dashboard' : '/welcome');

    }
  }

  function signout(){

    invalidateCache();
    window.location = '/sign_in'

  }

  function update(data){

    if (localStorage.getItem('user')){

      let user = JSON.parse(localStorage.getItem('user'));
      // if(Object.hasOwnProperty('expires') && user.expires < Date.now()){
      for (let key in data){

        if (typeof data[key] === 'object'){
          for (let innerKey in data[key]){

            user[key][innerKey] = data[key][innerKey]

          }
        }
        else {

          user[key] = data[key];

        }
      }

      localStorage.setItem('user', JSON.stringify(user));
      setUser(user);

    }
  }

  return (
    <AuthContext.Provider value={{

      user: user,
      signin: signin,
      signout: signout,
      update: update,
      permission: permissions[user?.permission]

    }}

    {...props} />
  );
}

// custom route object checks for an auth token before
// rendering the route – redirects if token is not present
export function PrivateRoute(props){

  return <Route {...props} render={ data => {

    // check user
    const user = JSON.parse(localStorage.getItem('user'));
    const path = window.location.pathname;

    if (user?.token){
      if (permissions[props.permission][props.permission]){

        // user has no subscription
        if (user.subscription !== 'active' && user.plan !== 'master'){

          if (!path.includes('/account/billing') &&
              !path.includes('/account/upgrade')){

            return <Redirect to='/account/billing' />

          }
        }

        // user is good
        return <props.render {...props }/>

      }
    }

    // user is not authenticated
    return <Redirect to='/sign_in' />;

  }}/>
}
