import React, {useEffect, useState} from 'react';
import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {Button} from "../../button/button";

export function StripeWrapperPaymentForm(props) {
    // const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState("");
    const elements = useElements();
    const stripe = useStripe();

    useEffect(() => {
        if (!stripe){
            return;
        }
        stripe.retrievePaymentIntent(props.clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe, props.clientSecret]);

    const paymentElementOptions = {
        layout: {
            type: "accordion",
            defaultCollapsed: false,
            radios: true,
            spacedAccordionItems: false
        },
        paymentMethodOrder: ['apple_pay', 'google_pay', 'card', 'klarna'],
    }

    const handlePurchase = async () => {
        if (!stripe || !elements) return;
        // setIsLoading(true);
        const {error} = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: 'https://example.com/checkout/complete',
            }
        });
        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else {
            console.log(error.message);
            setMessage("An unexpected error occurred.");
        }

        // setIsLoading(false);
    };
    return (
        <form>
            <PaymentElement options={paymentElementOptions}/>
            <Button
                text="Purchase"
                action={handlePurchase}
            />
            {message && <p>{message}</p>}
        </form>
    )
}