import {Animate} from "../../components/animate/animate";
import {Card} from "../../components/card/card";
// import React, {useContext} from "react";
import React from "react";
// import {ViewContext} from "../../components/view/view";
// import {AuthContext} from "../../app/auth";
// import {usePermissions} from "../../components/hooks/permissions";


export function MasterToolsKanari(props) {

    // const context = useContext(ViewContext);
    // const authContext = useContext(AuthContext);
    // const permissions = usePermissions();

    return(

        <Animate>
            <Card>
                Some Junk
                <img src="/fav/favicon-32x32.png" alt="favicon"/>
                <MicroFrontend name="TEST" />
            </Card>
        </Animate>
    );
}

class MicroFrontend extends React.Component {
    constructor(props){
        super(props);
        this.state = { isLoadParentForm: false };
    }
    componentDidMount() {
        const { name } = this.props;
        const scriptId = `micro-frontend-script-${name}`;

        if (document.getElementById(scriptId)) {
            this.renderMicroFrontend();
            return;
        }

        // fetch(`${host}/asset-manifest.json`)
        //     .then(res => res.json())
        //     .then(manifest => {
        //         const script = document.createElement('script');
        //         script.id = scriptId;
        //         script.crossOrigin = '';
        //         script.src = `${host}${manifest['main.js']}`;
        //         script.onload = this.renderMicroFrontend;
        //         document.head.appendChild(script);
        //     });
        const script = document.createElement('script');
        script.id = scriptId;
        script.crossOrigin = '';
        script.src = "/fav/bundle.js";
        document.head.appendChild(script);
        script.onload = () => {
            // script has loaded, you can now use it safely
            this.setState({ isLoadParentForm: true });

        }
        // script.onload = this.renderMicroFrontend;

        //         script.src = `${host}${manifest['main.js']}`;
        //         script.onload = this.renderMicroFrontend;
        //         document.head.appendChild(script);
    }

    // componentWillUnmount() {
    //     const { name, window } = this.props;
    //
    //     window[`unmountBrowse`](`${name}-container`);
    // }

    renderMicroFrontend = () => {
        // const { name, window, history } = this.props;

        // window[`renderBrowse`](`${name}-container`, history);
    };

    render() {
        // return <main id={`${this.props.name}-container`} />;
        return(
            <div>
                {this.state.isLoadParentForm ?
                    <cm-tools-element></cm-tools-element> : <></>
                }
            </div>
        )
    }
}