/***
*
*   MASTER ACCOUNTS
*   Manage all accounts signed up
*
**********/

import React, {useContext, useEffect} from 'react';
import {ViewContext, Card, Table, Animate, usePlans, useAPI, Button} from 'components/lib';
import Axios from "axios";

export function MasterAccounts(props){

  const context = useContext(ViewContext);
  const plans =  usePlans();
  const accounts = useAPI('/api/master/accounts');
  const entitlements = useAPI('/api/master/entitlements');
  const [approvalsList, setApprovalsList] = React.useState([]);
  const [entitlementApprovalsList, setEntitlementApprovalsList] = React.useState([]);

  const approveAccount = async (id) => {
    const res = await Axios({
      method: 'POST',
      url: '/api/master/account/approve',
      data: {
        id: id
      }
    });

  }

  const approveEntitlement = async (id) => {
    const res = await Axios({
      method: 'POST',
      url: '/api/master/entitlements/approve',
      data: {
        id: id
      }
    });

  }

  const refreshEntitlement = async (id) => {
    const res = await Axios({
      method: 'POST',
      url: '/api/master/entitlements/refresh',
      data: {
        id: id
      }
    });
  }

  useEffect(() => {

    if (accounts?.data) {
      console.log(accounts.data);
      if(accounts?.loading) return;
      let list = [];
      accounts.data.forEach((account) => {
        if (account["needs_activation"] === true) {
          // Copy the object so that the approve button doesn't appear in the main table.
          const clone = Object.assign({},account);
          list.push(clone);
        }
      });
      list.forEach((account) => {
        account["Approve"] = <Button text="Approve" action={()=>approveAccount(account.id)}></Button>
      });
      setApprovalsList(list);
    }

  }, [accounts]);

  useEffect(() => {

    if (entitlements?.data) {
      console.log(entitlements.data);
      if(entitlements?.loading) return;
      let list = [];
      entitlements.data.forEach((entitlement) => {
        // if (entitlement["is_approved"] === false) {
          // Copy the object so that the approve button doesn't appear in the main table.
          const clone = Object.assign({},entitlement);
          list.push(clone);
        // }
      });
      list.forEach((entitlement) => {
        entitlement["Approve"] = <Button text="Approve" action={()=>approveEntitlement(entitlement.id)}></Button>
        entitlement["Refresh"] = <Button text="Refresb" action={()=>refreshEntitlement(entitlement.id)}></Button>
      });
      setEntitlementApprovalsList(list);
    }

  }, [entitlements]);

  function editAccount(data, callback){

    context.modal.show({

      title: 'Edit Account',
      form: {
        id: {
          type: 'hidden',
          value: data.id
        },
        email: {
          label: 'Email',
          type: 'email',
          required: true,
          value: data.email
        },
        plan: {
          label: 'Plan',
          type: 'select',
          options: plans.data.list,
          default: data.plan,
          required: true,
        },
        active: {
          label: 'Status',
          type: 'select',
          default: data.active,
          options: [
            { value: 1, label: 'Active' },
            { value: 0, label: 'Cancelled' }
          ],
          required: true
        },
      },
      buttonText: 'Save',
      url: '/api/master/account',
      method: 'PATCH'

    }, (res) => {

      context.notification.show(data.name + ' was updated', 'success', true);
      callback(res);

    });
  }

  function deleteAccount(data, callback){

    context.modal.show({
      title: 'Delete Account',
      form: {
        id: {
          type: 'hidden',
          value: data.id
        }
      },
      buttonText: 'Delete Account',
      text: 'Are you sure you want to delete ' + data.name + '\'s account?',
      url: '/api/master/account',
      method: 'DELETE'

    }, () => {

      context.notification.show(data.name + ' was deleted', 'success', true);
      callback();

    });
  }

  return(
    <Animate>
      <Card loading={ false } title="Account Approvals Needed">
        <Table
            search
            hide={['id']}
            data={ approvalsList }
            loading={ accounts?.loading }
            badge={{ col: 'plan', color: 'blue' }}
        />
      </Card>
      <Card loading={ false } title="Entitlement Approvals Needed">
        <Table
            search
            data={ entitlementApprovalsList }
            loading={ entitlements?.loading }
            badge={{ col: 'plan', color: 'blue' }}
        />
      </Card>
      <Card loading={ false }>
        <Table
          search
          hide={['id']}
          data={ accounts?.data }
          loading={ accounts?.loading }
          badge={{ col: 'plan', color: 'blue' }}
          actions={{ edit: editAccount, delete: deleteAccount, email: true }}
        />
      </Card>
    </Animate>
  );
}
