import {atom} from "jotai";

export const currentAddressAtom = atom('');

export const scope1YearlyCellAtom = atom([
    { year:'2020', value: 3},
    { year:'2021', value: 4},
    { year:'2022', value: 5},
]);

export const scope1Atom = atom((get) => {
        const res = get(scope1YearlyCellAtom).filter(
            (item) => item.year === '2022'
        );
        console.log(res);
        return res.length ? res[0].value : 0;
    });

export const scope1SquareFootageAtom = atom(2);  // Square Footage of the building

export const scope1IntensityAtom = atom((get) => get(scope1YearlyCellAtom).map((item) => {return {year: item.year, value: item.value / get(scope1SquareFootageAtom)}}));

export const landSumAtom = atom(1);

