/***
*
*   SIGN UP
*   Signup form for account owners
*
**********/

import React, { useContext } from 'react';
import { Animate, AuthContext, Row, Card, PaymentForm, Link, usePlans } from 'components/lib';
import {useParams} from "react-router-dom";

export function Signup(props){

  const context = useContext(AuthContext);
  const plans = usePlans();
  const {ref} = useParams();
  console.log(ref);

  if (!plans.data)
    return false;

  return(
    <Animate type='pop'>
      <Row title='Create Your Account'>
        <Card loading={ false } restrictWidth center>
          <PaymentForm
            data={{
              name: {
                label: 'First Name',
                value: '',
                type: 'text',
                required: true,
                errorMessage: 'Please enter your first name'
              },
                // access_code: {
                //   label: "Beta Access Code",
                //     value: '',
                //     type: 'text',
                //     required: true,
                //     errorMessage: 'You must have an Access Code to continue'
                // },
              email: {
                label: 'Email',
                value: '',
                type: 'email',
                required: true,
              },
              password: {
                label: 'Password',
                type: 'password',
                required: true
              },
                signup_token: {
                    label: 'SignupToken',
                    type: 'hidden',
                    value: ref
                }
              // ,
              // plan: {
              //   label: 'Plan',
              //   type: 'select',
              //   options: plans.data.list,
              //   default: plan,
              //   required: true,
              // },
              // token: {
              //   label: 'Credit Card',
              //   type: 'creditcard',
              //   required: true,
              // }
            }}
            url='/api/account'
            method='POST'
            buttonText='Create Account'
            callback={ context.signin }
          />

          <span>Already registered? <Link url='/sign_in' text='Sign In' /></span>

        </Card>
      </Row>
    </Animate>
  );
}
