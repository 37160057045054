import React, {Fragment, useState} from "react";
import GoogleMapReact from "google-map-react";
import {Button} from "../../../components/lib";
import {CustomMarker} from "./custom_marker";

export function MapScannerWrapper(props){
    const [processing, setProcessing] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [places, setPlaces] = useState([]);
    const mapRef = React.createRef();
    const [center, setCenter] = useState({lat: 0, lng: 0});
    const [resultIdx, setResultIdx] = useState(0);
    const [showMarker1, setShowMarker1] = useState(false);
    const [filterFound, setFilterFound] = useState(false);
    const defaultProps = {
        center: {
            lat: 10.99835602,
            lng: 77.01502627
        },
        zoom: 16
    };

    const findPlaces = (map) => {
        // const marker = new window.google.maps.Marker({
        //     map: map,
        //     position: {lat: center.lat, lng: center.lng},
        // });
        const service = new window.google.maps.places.PlacesService(map);
        service.nearbySearch({
            location: {lat: center.lat, lng: center.lng},
            rankBy: window.google.maps.places.RankBy.DISTANCE,
            types: ['establishment']
        }, function(results, status) {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                setPlaces(results);
                let found = filterFound;
                let place = results[resultIdx];
                if(props.filter !== ""){
                    let filtered = results.filter((r) => {
                        return r.name.toLowerCase().includes(props.filter.toLowerCase());
                    });
                    if(filtered.length > 0){
                        found = true;
                        place = filtered[0];
                        setResultIdx(results.indexOf(place));
                    }
                }
                setFilterFound(found);
                setProcessing(false);
                props.callback(props.idx, place, found);
                setShowMarker1(true);
            } else {
                alert('PlacesService was not successful for the following reason: ' + status);
            }
        });
    }

    const nextClick = () => {
        const newIdx = resultIdx + 1;
        let place = places[newIdx];
        props.callback(props.idx, place);
        setResultIdx(newIdx);
    }

    const prevClick = () => {
        const newIdx = resultIdx > 0 ? (resultIdx - 1) : 0;
        let place = places[newIdx];
        props.callback(props.idx, place);
        setResultIdx(newIdx);
    }

    if(props.shouldProcess){
        if(!processing) {
            if(!completed){
                setProcessing(true);
                const geocoder = new window.google.maps.Geocoder();
                geocoder.geocode( { 'address': props.address}, function(results, status) {
                    if (status === 'OK') {
                        console.log(results[0]);
                        let lat = results[0].geometry.location.lat();
                        let lng = results[0].geometry.location.lng();
                        setCenter({lat: lat, lng: lng});
                        setCompleted(true);
                    } else {
                        alert('Geocode was not successful for the following reason: ' + status);
                    }
                });
            }
        }
    }

    return (
        <div>
            {
                completed &&
                <Fragment>
                    <div style={{height: '200px', width: '200px'}}>
                        <GoogleMapReact
                            bootstrapURLKeys={{key:"AIzaSyCYzFo6W4UbCUEnSkYBR_VXNfjsldx7zEw"}}
                            ref={mapRef}
                            defaultZoom={defaultProps.zoom}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({map, maps}) => findPlaces(map)}
                            center={center}
                        >
                            {showMarker1 &&
                                <CustomMarker
                                    lat={places[resultIdx].geometry.location.lat()}
                                    lng={places[resultIdx].geometry.location.lng()}
                                    text={places[resultIdx].name} />
                            }
                        </GoogleMapReact>
                    </div>
                    <Button text={"<"}  action={prevClick} small/>
                    <Button text={">"} action={nextClick} small/>
                </Fragment>
            }
        </div>
    )
}