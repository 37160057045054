import React, { Fragment } from 'react';
import Style from './disabled.module.scss';
import ClassNames from "classnames";

export function DisabledDiv(props){
    const css = ClassNames([
       props.className,
       props.disabled && Style.disabled
    ]);

  return (
    <Fragment>
        <div className={css}>
            {props.disabled && <div className={Style.overlay}/>}
            {props.children}
        </div>
     </Fragment>
  );
}
