import React from 'react';
import Style from "./report1.module.scss";
import {currentAddressAtom, scope1Atom, scope1IntensityAtom, scope1YearlyCellAtom} from "../../../state/glimmerState";
import {LineSegment, VictoryAxis, VictoryBar, VictoryChart, VictoryLine, VictoryScatter} from "victory";
import {useAtom} from "jotai";
import placeSVG from './images/place.svg';


export function TestHolder(props) {
    const [currentAddress] = useAtom(currentAddressAtom);  // TODO: Change to currentAddressAtom
    const [scope1] = useAtom(scope1Atom);
    const [scope1Yearly] = useAtom(scope1YearlyCellAtom);
    const [scope1SquareFootage] = useAtom(scope1IntensityAtom);
    console.log(scope1SquareFootage);
    if(!props.visible)
        return null;
    return (
        <div className={Style.testHolder}>
            <div className={Style.text1}>
                Current Location
            </div>
            <div className={Style.placeWrapper}>
                <div className={Style.placeIcon}>
                    <img src={placeSVG}></img>
                </div>
                <div className={Style.text2}>
                    <span>{currentAddress}</span>
                </div>
            </div>
            <div className={Style.text3}>
                Asset Emissions Profile
            </div>
            <div className={Style.text4}>
                The emissions are based on using actual measurement from satellites and ground sensors. These are typically reporting as total metric tons for scope 1 (direct emissions), scope 2 (emissions from energy purchase), and scope 3 (indirect emissions). We report on scope 1 and 2 below using direct measurements. All units are in CO<sub>2</sub>e and in metric tons.
            </div>
            <div className={Style.text7}>
                <u>Total Scope 1 Emissions:</u> {scope1===null ? 'N/A' : scope1.toLocaleString( undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})} Metric Tons CO<sub>2</sub>e (2022)
            </div>
            <div className={Style.text5}>
                Scope 1 GHG Emissions (Metric tons CO<sub>2</sub>e)
            </div>
            <div className={Style.chartWrapper}>
                <VictoryChart
                    domainPadding={{x: [0,0]}}
                    minDomain={{y: 0}}
                    animate={{duration: 2000, easing: "bounce"}}
                    height={250}
                    width={600}
                >
                    <VictoryLine  data={scope1Yearly} x="year" y="value" interpolation="natural" style={{
                        data: { stroke: "#7fae17" },
                    }}/>
                    {/*TODO: Remove and Move Style out of this file*/}
                    <VictoryAxis
                        style={{
                            axis: {stroke: '#e0e4e8', strokeWidth: 1},
                            tickLabels: {fontFamily: "Exo 2", fontWeight: 800, fontSize: 14, fill: "#91989e"},
                    }}
                    />
                    <VictoryAxis
                        dependentAxis
                        // label='Scope 1 (Metric Tons CO2)'
                        style={{
                            axis: {stroke: 'transparent'}, // Remove the axis line
                            tickLabels: {fontFamily: "Exo 2", fontWeight: 800, fontSize: 13, fill: "#91989e", padding:30},
                            axisLabel: {fontFamily: "Exo 2", fontWeight: 600, fontSize: 14, padding: 0, fill: "#91989e"}
                        }}
                        gridComponent={<LineSegment style={{stroke: "#e0e4e8", strokeWidth: 1}}/>}
                    />
                    <VictoryScatter data={scope1Yearly} x="year" y="value" size={8} style={{data: {fill: "#7fae17"}}}/>
                </VictoryChart>
            </div>
            <div className={Style.text6}>
                <b>Note</b>: Scope 1 emissions are direct emissions from asset operations.
            </div>
            <div className={Style.text5b}>
                Scope 1 GHG Emissions Intensity (Metric tons CO<sub>2</sub>e / sqft)
            </div>
            <div className={Style.chartWrapper}>
                <VictoryChart
                    domainPadding={{x: [40,10]}}
                    minDomain={{y: 0}}
                    width={600}
                    height={250}
                >
                    <VictoryAxis
                        // offsetX={100}
                        style={{
                            axis: {stroke: '#e0e4e8', strokeWidth: 1},
                            tickLabels: {fontFamily: "Exo 2", fontWeight: 800, fontSize: 14, fill: "#91989e"},
                        }}
                        tickFormat={(data) => `${data}`}
                    />
                    <VictoryAxis
                        dependentAxis
                        // label='Scope 1 Intensity'
                        style={{
                            axis: {stroke: 'transparent'}, // Remove the axis line
                            tickLabels: {fontFamily: "Exo 2", fontWeight: 800, fontSize: 13, fill: "#91989e", padding:10},
                            axisLabel: {fontFamily: "Exo 2", fontWeight: 600, fontSize: 13, padding: 0, fill: "#91989e"}
                        }}
                        gridComponent={<LineSegment style={{stroke: "#e0e4e8", strokeWidth: 1}}/>}
                    />
                    <VictoryBar
                        data={scope1SquareFootage}
                        x="year" y='value'
                        cornerRadius={10}
                        style={{
                            data: {
                                fill: "#7fae17",
                                stroke: "#6c9413",
                            },
                        }}
                        barRatio={0.8}
                    />
                </VictoryChart>
            </div>
            <div className={Style.text6}>
                <b>Note: </b>Intensity is the measure of emissions per operational square foot (or square meter).
            </div>
        </div>
    );
}