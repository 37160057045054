import React, {Fragment, useState} from 'react';
// import { Elements } from "@stripe/react-stripe-js";
import { CheckoutForm } from "../../../components/checkout/checkout_form/checkout_form";
import {Button, Card, NumberInput} from "../../../components/lib";
import Style from './credits_landing.module.scss';
import {HorizontalDivider} from "../../../components/layout/divider/horizontal_divider";

export function PurchaseCreditsFlow(props) {
    const [showCheckout, setShowCheckout] = useState(false);
    const [numOfReports, setNumOfReports] = useState(1);

    const handleGoToCheckout = () => {

        setShowCheckout(true);
    }

    const numOfReportsOnChange = (name, value, valid) => {
        setNumOfReports(value);
    }

    if (showCheckout) return (
        <Fragment>
            <Card
                shadow
            >
                <h1>Number of Reports: {numOfReports}</h1>
                <CheckoutForm/>
                <div className={Style.buttonHolder}>
                    {/*<Button*/}
                    {/*    className={Style.nextButton}*/}
                    {/*    text="Purchase"*/}
                    {/*    action={handlePurchase}*/}
                    {/*/>*/}
                </div>
            </Card>
        </Fragment>
    );

    return (
        <Fragment>
            <Card
                shadow
            >
                <h1>Report Order Form</h1>
                <HorizontalDivider/>
                <Card
                    className={Style.formWrapper}
                    shadow
                >
                    <div className={Style.formRow}>
                        <h2>Number of Reports</h2>
                        <NumberInput
                            name={"numOfReports"}
                            min={1}
                            value={numOfReports}
                            max={100}
                            onChange={numOfReportsOnChange}
                        />
                        <div>$</div>
                    </div>
                    <HorizontalDivider/>

                </Card>
                <div className={Style.buttonHolder}>
                    <Button
                        className={Style.nextButton}
                        text="Next"
                        action={handleGoToCheckout}
                    />
                </div>
            </Card>
        </Fragment>
    );
}