/***
*
*   APP LAYOUT
*   Main application layout containing the navigation
*   and header (title, secondary nav and signed in user)
*
*   PROPS
*   title: title of the view
*
**********/

import React, { Fragment, useContext } from 'react';
import { AuthContext, AppNav, Header, HoverNav, Button } from 'components/lib';
import ClassNames from 'classnames';
import Style from './app.module.scss';
import '../layout.scss'; // globals
import sageLogo from './SAGE Transparent White Cropped.png';
import restLogo from './REST Transparent White Cropped.png';
import settingsSVG from './images/settings.svg';
import notificationsSVG from './images/notifications.svg';
import vbarSVG from './images/vbar.svg';
import profilePNG from './images/profile.png';

export function AppLayout(props){

  // context & style
  const context = useContext(AuthContext);
  const css = ClassNames([ Style.app, 'with-sidebar' ]);

  return (
    <Fragment>
      <AppNav
        type='popup'
      />

      <main className={ css }>

        <Header title={ props.title }>
            {/*FIXME: Move into Header component*/}
            <div className={Style.growdiv}></div>
            <div className={Style.settingsGear}>
                <img src={settingsSVG} alt="settings Button"></img>
            </div>
            <div className={Style.notificationsSVG}>
                <img src={notificationsSVG} alt="notifications Button"></img>
            </div>
            <div className={Style.vbarSVG}>
                <img src={vbarSVG} alt="vertical bar"></img>
            </div>
            <div className={Style.profileWrap}>
                <div className={Style.profileImg}>
                    <img src={profilePNG} alt="profile picture"></img>
                </div>
                <div className={Style.user}>
                    <div className={Style.userName} onClick={e => window.location = '/account'}>
                        { context.user.name }
                    </div>
                    <div className={Style.signOut} onClick={context.signout}>
                        Sign out
                    </div>
                </div>
            </div>
          {/*<HoverNav icon='user' label={ context.user.name } align='right' dark>*/}
          {/*  <Button text='Account Settings' action={ e => window.location = '/account' }/>*/}
          {/*  <Button text='Signout' action={ context.signout }/>*/}
          {/*</HoverNav>*/}
        </Header>

          <div className={Style.content}>
        { <props.children {...props.data }/> }
          </div>
      </main>
    </Fragment>
  );
}
