import React, {useState} from 'react';
import ClassNames from 'classnames';
import {Label, Error, Icon, Button} from 'components/lib';
import Style from './input.module.scss';

function address_factory(len) {
    return { id: 'address_' + len,
        name: 'Address ' + len,
        value: ''
    }
}

export function ProjectAddressConfigInput(props) {
    const [addresses, setAddresses] = useState([address_factory(0)]);
    let options = props.options;
    let multipleAddresses = true;
    // console.log(options);
    if(options !== undefined && options?.singleAddress)
        multipleAddresses = !options.singleAddress;
    // console.log(multipleAddresses);

    function update() {
        let value = addresses.map(item => {return item.value});
        props.onChange(props.name, value, true)
    }

    function address_change(index, value) {
        let updatedList = [...addresses];
        updatedList[index].value = value;
        setAddresses(updatedList);
        update();
    }

    function add_address_box() {
        setAddresses(addresses => addresses.concat(address_factory(addresses.length)));
        update();
    }

    function remove_by_index(name){
        setAddresses(addresses => addresses.filter(item => item.name !== name));
        update();
    }

    const error = props.errorMessage || 'Please enter a value';

    // style
    const css = ClassNames([

        Style.inline_textbox_address,
        props.className,
        props.valid === true && Style.success,
        props.valid === false && Style.error,

    ]);

    const component_css = ClassNames( [
        Style.input,
        Style.address_wrapper_input
    ])

    return (
        <div className={component_css}>

                <Label
                    text={props.label}
                    required={props.required}
                    for={props.name}
                />
                { addresses.map((option, index) => {
                    return (
                        <div
                            key={index}
                            style={{display: "flex", alignItems: "center"}}
                        >
                        <input
                            type='text'
                            id={option.id}
                            key={'row_' + index}
                            name={option.name}
                            className={css}
                            value={option.value || ''}
                            placeholder={props.placeholder}
                            onChange={ e => address_change(index, e.target.value) }
                        />
                            { multipleAddresses === true &&
                                <Button
                                    text="Remove"
                                    verySmall
                                    color='gray'
                                    action={e => remove_by_index(option.name)}
                                />
                            }
                        </div>
                    );
                })}
            { multipleAddresses === true &&
                <Button
                text = 'Add Address'
                // color='blue'
                small
                action={e => add_address_box()}
                />
            }
                {props.valid === false &&
                    <Error message={error}/>}

                {props.valid === true &&
                    <Icon
                        image='check'
                        color='#8CC57D'
                        className={Style.successIcon}
                        size={16}
                    />}
        </div>
    );
}
