import React, {Fragment, useEffect, useState} from 'react';
import {ProjectSteps} from "./steps";
import {Row} from "../../layout/row/row";
import {Loader} from "../../lib";

export function ProjectStepsHolder(props) {
    const [percentCompleted, setPercentCompleted] = useState(0);
    const [statusIndex, setStatusIndex] = useState(0);

    useEffect( ()=> {
        const status = props.status;
        if(status === undefined)
            return; // Still loading
        let sIdx = Object.keys(props.options).indexOf(status);
        if (status !== 'Complete') {
            sIdx += 1;
        }
        setStatusIndex(sIdx);
        setPercentCompleted(props.options[status].percent);
    }, [props.projectName, props.options, props.status]);

    if(props.projectName === undefined || props.status === undefined)
        return (<Loader/>)

    return (
        <Fragment>
            <Row
                title={'Project: ' + props.projectName + ' (' + props.status + ')'}
                desc='Below shows the status of the current project'
            >
                <ProjectSteps
                    percentCompleted={percentCompleted}
                    items={Object.values(props.options)}
                    activeIndex={statusIndex}
                />
            </Row>
        </Fragment>
    );
}