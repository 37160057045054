import { OnboardingView } from 'views/onboarding/onboarding';
import { AddressForm } from "../views/sage/sage_new_request";
import { SageLanding } from "../views/sage/sage_landing";
import { SageProjectLanding } from "../views/sage/sage_project_landing";
import { RestLanding } from "../views/rest/rest_landing";
import { RestNewRequest } from "../views/rest/rest_new_request";
import { RestProjectLanding } from "../views/rest/rest_project_landing";
import { Landing } from "../views/landing/landing";
import {PurchaseCreditsFlow} from "../views/purchase_flows/credits_landing/credits_landing";

export default [
  {
    path: '/dashboard',
    view: Landing,
    layout: 'app',
    permission: 'user',
    title: 'Asset Analysis & ESG Benchmarks'
  },
  {
    path: '/sage',
    view: SageLanding,
    layout: 'app',
    permission: 'user',
    title: 'SAGE: Greenhouse Gas Assessment'
  },
  {
    path: '/sage/new_request',
    view: AddressForm,
    layout: 'app',
    permission: 'user',
    title: 'SAGE: Greenhouse Gas Assessment - Submit New Request'
  },
  {
    path: '/sage/project/:project',
    view: SageProjectLanding,
    layout: 'app',
    permission: 'user',
    title: 'SAGE: Greenhouse Gas Assessment - Project Tracker'
  },
  {
    path: '/rest/project/:project',
    view: RestProjectLanding,
    layout: 'app',
    permission: 'user',
    title: 'REST: Real Estate Sustainability Tool - Project Tracker'
  },
  {
    path: '/rest',
    view: RestLanding,
    layout: 'app',
    permission: 'user',
    title: 'REST: Real Estate Sustainability Tool'
  },
  {
    path: '/rest/new_rest_request',
    view: RestNewRequest,
    layout: 'app',
    permission: 'user',
    title: 'REST: Real Estate Sustainability Tool - Submit New Request'
  },
  {
    path: '/welcome',
    view: OnboardingView,
    layout: 'onboarding',
    permission: 'user',
    title: 'Welcome'
  },
  {
    path: '/credits_purchase',
    view: PurchaseCreditsFlow,
    layout: 'app',
    permission: 'user',
    title: 'Purchase Reports'
  },
]
