export default [
  [
    { name: 'One Report', checked: true },
    { name: 'Demographics', checked: true },
    { name: 'Climate Risk Analytics', checked: true },
    { name: 'Future Climate Change', checked: true },
    { name: 'Scope 1, 2, 3 Emissions', checked: false },
    { name: 'Export Data', checked: false },
    { name: 'API Access', checked: false },
    { name: 'Additional Team Members', checked: false },
  ],
  [
    { name: '5 Reports', checked: true },
    { name: 'Demographics', checked: true },
    { name: 'Climate Risk Analytics', checked: true },
    { name: 'Future Climate Change', checked: true },
    { name: 'Scope 1, 2, 3 Emissions', checked: true },
    { name: 'Export Data', checked: true },
    { name: 'API', checked: false },
    { name: 'Additional Team Members', checked: false },
  ],
  [
    { name: 'Unlimited Reports', checked: true },
    { name: 'Demographics', checked: true },
    { name: 'Climate Risk Analytics', checked: true },
    { name: 'Future Climate Change', checked: true },
    { name: 'Scope 1, 2, 3 Emissions', checked: true },
    { name: 'Export Data', checked: true },
    { name: 'API', checked: true },
    { name: 'Additional Team Members', checked: true },
  ]
]
